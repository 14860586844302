import { POSTS_PER_PAGE } from '../constants/pagination';
import { startLoadingCategory } from './start-loading-category';
import { stopLoadingCategory } from './stop-loading-category';
import fetchPosts from './fetch-posts';
import { actionPromiseCache } from '../services/action-promise-cache';
import { isExperimentEnabled } from '../selectors/experiments-selectors';
import { EXPERIMENT_INCREASE_POST_LIST_ITEM_COUNT } from '@wix/communities-forum-client-commons/dist/src/constants/experiments';

const finishLoading = (dispatch, categoryId) => {
  dispatch(stopLoadingCategory(categoryId));
};

export const fetchCategoryPosts = actionPromiseCache(
  ({ siteMemberId, categoryId, page, sort, postType, pageSize }) => (dispatch, getState) => {
    const state = getState();
    const isPostCountIncreaseEnabled = isExperimentEnabled(
      state,
      EXPERIMENT_INCREASE_POST_LIST_ITEM_COUNT,
    );

    // @TODO update POSTS_PER_PAGE constant to 30, when experiment is merged
    pageSize = !pageSize ? (isPostCountIncreaseEnabled ? 30 : POSTS_PER_PAGE) : pageSize;

    dispatch(startLoadingCategory(categoryId));
    const promise = dispatch(
      fetchPosts({ siteMemberId, categoryId, page, pageSize, sort, postType }),
    );

    return promise
      .then(() => finishLoading(dispatch, categoryId))
      .catch(() => finishLoading(dispatch, categoryId))
      .then(() => promise);
  },
  {
    cacheKeyBuilder: args => `${args.categoryId}${args.page}${args.sort}`,
    cacheTime: 1000,
    ignoreResolve: true,
  },
);
