import { trim } from 'lodash';
import { getPreviousMatches, getRouteParams } from '../../common/router/router-selectors';
import { getLocation } from '../../common/store/location/location-selectors';
import { getCategories } from '../../common/selectors/categories-selectors';
import { navigateWithinForum } from '../../common/actions/navigate-within-forum';
import { isExperimentEnabled } from '../selectors/experiments-selectors';
import {
  EXPERIMENT_URL_CUSTOMIZATION,
  EXPERIMENT_FORUM_TABS,
} from '@wix/communities-forum-client-commons/dist/src/constants/experiments';
import { UrlMappingsKeys } from '@wix/url-mapper-utils';
import {
  createFeedTypeQueryParam,
  getActiveNavigationItemName,
} from '../containers/header-navigation';

const getPathBeforeSearch = state => {
  const prevMatches = getPreviousMatches(state) || [];
  const actualPrevPath = prevMatches.find(({ pathname }) => !pathname.startsWith('/search')) || {};
  return trim(actualPrevPath.pathname, '/');
};

const removeUnneededParams = (state, parts) => {
  const routeParams = getRouteParams(state);
  const paramsToPop = ['deepLinkData', 'page'];
  paramsToPop.forEach(p => {
    if (routeParams[p] !== undefined) {
      parts.pop();
    }
  });
};

export const goBack = () => async (dispatch, getState, { wixCodeApi }) => {
  const state = getState();
  const categories = getCategories(state);
  const hasOneCategory = categories.length === 1;
  const currentLocation = getLocation(state);
  if (isExperimentEnabled(state, EXPERIMENT_URL_CUSTOMIZATION)) {
    const { categorySlug } = getRouteParams(state);
    const { key } =
      (wixCodeApi.site &&
        wixCodeApi.site.getCustomizedUrlSegments &&
        (await wixCodeApi.site.getCustomizedUrlSegments(currentLocation.pathname))) ||
      {};
    switch (key) {
      case UrlMappingsKeys.FORUM_POST_PAGINATION_AND_DEEP_LINK_DATA:
      case UrlMappingsKeys.FORUM_POST_DEEP_LINK_DATA:
      case UrlMappingsKeys.FORUM_POST_PAGINATION:
      case UrlMappingsKeys.FORUM_POST: {
        return dispatch(navigateWithinForum(`/${categorySlug}`));
      }
      case UrlMappingsKeys.FORUM_CATEGORY_PAGINATION:
      case UrlMappingsKeys.FORUM_CATEGORY:
        return dispatch(navigateWithinForum('/'));
      default:
    }
  }

  if (isExperimentEnabled(state, EXPERIMENT_FORUM_TABS) && currentLocation.prevPath === '/') {
    const activeTabName = getActiveNavigationItemName(state);

    // TODO: Implement actual go back looking at stack
    return dispatch(
      navigateWithinForum(activeTabName ? `/?${createFeedTypeQueryParam(activeTabName)}` : '/'),
    );
  }

  const parts = trim(currentLocation.pathname, '/').split('/');
  parts.pop();

  if (currentLocation.pathname.startsWith('/search/')) {
    parts.splice(-1, 1, getPathBeforeSearch(state));
  } else if (currentLocation.pathname.startsWith('/search')) {
    parts.push(trim(currentLocation.prevPath, '/'));
  }
  removeUnneededParams(state, parts);
  if (hasOneCategory && parts.length === 0) {
    parts.push(categories[0].slug);
  }
  const path = '/' + parts.join('/');

  return dispatch(navigateWithinForum(path));
};
