import { FeedType } from './models';
import { createFeedTypeQueryParam } from './create-feed-type-query-param';

export const getFeedUrl = (
  feedType: FeedType,
  isMainPageCategories: boolean,
): string | undefined => {
  if (
    feedType === FeedType.MyPosts ||
    (!isMainPageCategories && feedType === FeedType.Categories) ||
    (isMainPageCategories && feedType === FeedType.Posts)
  ) {
    return `/?${createFeedTypeQueryParam(feedType)}`;
  }

  return;
};
